import { Suspense,lazy,memo } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import configureStoreProd from "./Pages/config/configureStore.prod";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import "./App.css";
import "./scss/main.scss";

const { persistor, store } = configureStoreProd();
sessionStorage.clear();

const Loading=({id='',className=''})=>{
  return <>
  <div id={id} className={`loaderDiv ${className}`}>
          <div>
            <img
              src="/assets/img/loader.gif"
              alt="logo"
              className="loaderlogo"
            />
          </div>
        </div>
  </>
}

const App=memo(function App() {
  const CLIENT_ID = "90361535027-69b2c3qbjhla03rt54iaaqkmcm7q2flu.apps.googleusercontent.com";

  const routes = [
    { url: "",path:'Home' },
    // { url: "/", element: <Navigate to="/login" /> },
    { url: "/login", path:'Home' },
    { url: "/signup",path:'Home' },
    { url: "/cards",path:'Cards'},
    { url: "/getstarted/step1",path:'GetStarted/Step1'},
    { url: "/getstarted",path:'GetStarted'},
    { url: "/favourites",path:'Dashboard' },
    {url:'/verify/details', path:'Login/VerrifyDetails'},
    { url: "/orders", path:'myOrders/Orders'},
    { url: "/rewards",path:'myRewards/Rewards'},
    { url: "/subscription",path:'mySubscription/Subscription'},
    { url: "*", path:'NotFoundPage'  },
    { url: "/myprofile",path:'Profile'  },
    { url: "/profile", path:'Profile' },
    { url: "/profile/:tab", path:'Settings' },
    { url: "/forgotpassword", path:'Forgotpassword' },
    { url: "/resetpassword", path:'Resetpassword' },
    { url: "/roles", path:'Roles' },
    { url: "/roles/add",path:'Roles/AddEdit' },
    { url: "/roles/edit/:id",path:'Roles/AddEdit' },
    { url: "/verify", path:'Verify' },
    { url: "/products",path:'Products/Listing' },
    { url: "/product-detail/:id", path:'Product-Detail/View' },
    { url: "/support", path:'Support' },
    { url: "/discover",path:'Discover' },
    { url: "/myjournal",path:'MyJournal' },
    { url: "/myjournal/:id",path:'MyJournal/View' },
    { url: "/products/reviewed",path:'Products/Reviewed' },
    { url: "/products/list", path:'Products/Listing' },
    { url: "/products/tags",path:'Products/Tag' },
    { url: "/products/recommended",path:'Products/Recommended' },
    { url: "/privacy-policy",path:'Privacy' },
    { url: "/terms-condition",path:'Terms'},
    { url: "/blogs",path:'blogs'},
    { url: "/blog/:slug", path:'blogs/Detail' },
    { url: "/videos", path:'videos' },
    { url: "/audio", path:'audios' },
    { url: "/notifications",path:'notifications' },
    { url: "/chats", path:'chats/Chats' },
    { url: "/messages", path:'Messages/Chats' },
    { url: "/video-detail/:id",path:'videos/Detail'},
    { url: "/audio-detail/:id",path:'audios/Details' },
    { url: "/membership", path:'membership' },
    { url: "/membership-details",path:'membership/Details' },
    { url: "/invite-a-member", path:'invite' },
    { url: "/referrals", path:'referals' },
    { url: "/faq", path:'Faq'},
    { url: "/cart", path:'Cart'},
    { url: "/checkout", path:'Checkout'},
  ];

  return (
    <>
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <Provider store={store}>
          <PersistGate loading={<Loading/>} persistor={persistor}>
            <Router>
            <Suspense fallback={<Loading />}>
              <Routes>
                {routes.map((itm:any) => {
                  // const Component = require(`./Pages/${itm.path}`).default;
                  const Component = lazy(() => import(`./Pages/${itm.path}`));
                  return <Route path={itm.url} element={itm.element||null} Component={itm.element?null:Component} />;
                })}
              </Routes>
              </Suspense>
            </Router>
          </PersistGate>
        </Provider>
        <Loading id="loader" className="d-none" />
        <ToastContainer position="top-right" />
      </GoogleOAuthProvider>
    </>
  );
})

export default App;
