/*********** Reduceres defined here *********/

import { persistCombineReducers } from "redux-persist";
import storage from "redux-persist/es/storage"; // default: localStorage if web, AsyncStorage if react-native
import user from "./modules/user";
import loader from "./modules/loader";
import search from "./modules/search";
import categories from "./modules/categories";
import membership from "./modules/membership";
import cart from "./modules/cart";
import subscription from "./modules/subscription";

const userPersistConfig = {
  key: "admin-app",
  storage: storage,
  blacklist: ["loader"],
};

export default persistCombineReducers(userPersistConfig, {
  loader,
  user,
  search,
  categories,
  membership,
  subscription,
  cart
});
