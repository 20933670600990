/*
 * @file: cart.js
 * @description: Reducers and actions for store/manipulate user's  data
 * @author: Mohit Kumar
 */

/******** Reducers ********/

const initialState = {data:[]};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "CART_SUCCESS":
      return {
        ...state,
        ...action.data,
      };
      case 'LOG_OUT':
        return { ...initialState };
    default:
      return state;
  }
}
