const initialState = {
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'IS_LOADING':
      return { ...state, isLoading: action.data };

    case 'LOG_OUT':
      return { ...initialState };
    case 'LOADER_OUT':
      return { ...initialState };

    default:
      return state;
  }
}
