const initialState = {
  categories: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "USER_CATEGORIES":
      return {
        ...state,
        categories: action.data,
      };

    default:
      return state;
  }
}
