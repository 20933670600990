const initialState = {
  membership: {
    plan: "",
    box: "",
    profile_details: {},
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "USER_MEMBERSHIP":
      return {
        ...state,
        membership: { plan: action.data },
      };

    case "USER_MEMBERSHIP_BOX":
      return {
        ...state,
        membership: { ...state.membership, box: action.data },
      };

    case "USER_MEMBER_PROFILE":
      return {
        ...state,
        membership: { ...state.membership, profile_details: action.data },
      };
      case 'LOG_OUT':
        return { ...initialState };

    default:
      return state;
  }
}
