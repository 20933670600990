const initialState = {
  loggedIn: false,
  notifications: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        ...{ loggedIn: true },
        ...action.data,
      };
    case "LOG_OUT":
      localStorage.removeItem("token");
      return initialState;

    default:
      return state;
  }
}
